import React, { FunctionComponent } from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { add } from 'date-fns'

import { Links } from '../components/Links'
import { LandingTopbar } from '../components/LandingTopbar'
import { Layout } from '../components/LayoutLanding'
import { SEO } from '../components/SEO'
import { stripIndent } from 'common-tags'

interface IndexPageProps {
  location: Location
}

const IndexPage: FunctionComponent<IndexPageProps> = ({ location }) => {
  return (
    <Layout>
      <SEO
        title="Vanna | Infraestrutura de split para Pix"
        description={
          'Utilize a plataforma de cobranças Vanna para receber em segundos! O meio de pagamentos instantâneos Pix é o motor para realizar cobranças na plataforma Vanna. Cobre uma única vez ou de forma recorrente, você que escolhe!'
        }
        tags={[
          'Cobranças',
          'Cobranças recorrentes',
          'Cobranças instantâneas',
          'Pix',
          'Plataforma de cobranças',
          'Receba em segundos',
        ]}
      />

      <LandingTopbar />
      <Links />

      <section className="px-4 pt-10 sm:px-6 pb-12 lg:pb-20 mx-auto max-w-screen-xl ">
        <span className="uppercase text-xs font-bold pb-4">
          api para extensões
        </span>
        <div className="max-w-4xl mb-16">
          <h1 className="text-3xl my-4 lg:text-4xl font-semibold">
            Use a Vanna via API para integrar ao seu sistema
          </h1>
          <p className="text-base xl:text-xl">
            Crie soluções especializadas reutilizando todas as funcionalidades
            de recebimento e split que a Vanna já tem. Para ter acesso a
            documentação completa e credenciais de API entre em contato.
          </p>
          <a
            className="text-yellow-brand hover:underline pt-4"
            target="_blank"
            rel="noopener"
            href="https://api.whatsapp.com/send/?phone=5515981779550&amp;text=Fala+Guilherme%21+Estou+interessado+na+Vanna%2C+meu+nome+%C3%A9&amp;app_absent=0"
          >
            Entrar em contato
          </a>
        </div>
      </section>
      <footer className="mt-auto">
        <p className="w-full text-sm text-center text-md py-2">
          Copyright © {new Date().getFullYear()} Vanna. Todos os direitos
          reservados.
          <Link
            to="/funcionalidades"
            className="text-yellow-brand hover:underline"
          >
            Termos de uso
          </Link>{' '}
          e{' '}
          <Link
            to="/funcionalidades"
            className="text-yellow-brand hover:underline"
          >
            Política de privacidade
          </Link>
        </p>
      </footer>
    </Layout>
  )
}

export default IndexPage
